import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AmpleLogo from 'images/ample-logo.png';
import Commercial from 'images/commercial-1.jpg';
import Residential from 'images/residential-4.jpg';
import ContactSection from '../components/contactSection';

import 'scss/styles.scss';
import './index.scss';

const IndexPage = () => (
  <Layout>
    <div className='uk-position-relative uk-flex uk-flex-center background'>
      <div className='uk-position-relative uk-container'>
        <div
          className='uk-section uk-section-medium uk-width-1-1 uk-dark uk-scrollspy-inview uk-animation-slide-bottom-medium container'
          data-uk-scrollspy='cls: uk-animation-slide-bottom-medium; repeat: true'
        >
          <SEO title='Home' />
          <div
            className='uk-grid-large uk-flex uk-flex-middle uk-flex-center@m uk-grid'
            data-uk-grid
          >
            <div className='uk-width-1-2@m uk-first-column uk-text-secondary align-center-mobile'>
              <div className='ample-heading'>
                <mark className='white-mark'>You Build It.</mark>
              </div>
              <div className='ample-heading'>
                <mark className='white-mark'>We Maintain It.</mark>
              </div>
              <div className='uk-text-large text-block white-text'>
                <strong>Ample Maintenance</strong> formed out of our{' '}
                <strong>passion for helping people</strong>. Our company was
                founded on providing high quality work with integrity. ​{' '}
                <strong>Ample Maintenance</strong> is a{' '}
                <strong>trusted and experienced</strong> building maintenance
                company. We take great pride in our ability to keep our client's
                facilities <strong>operating at it's highest capacity</strong>.
                Our business is keeping your business up and running seamlessly,{' '}
                <strong>so you can perform your best</strong>. Enjoy{' '}
                <strong>the peace of mind</strong> knowing you have a{' '}
                <strong>competent, honest team</strong> managing{' '}
                <strong>all your maintenance needs</strong>.
              </div>
            </div>
            <div className='uk-width-expand@m'>
              <img src={AmpleLogo} className='ample-logo' />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='divider uk-background-default'></div>

    {/* NEXT SECTION  */}
    <div className='uk-cover-container uk-background-default'>
      <div className='uk-section uk-section-large'>
        <div className='uk-container uk-position-relative uk-text-center'>
          <div
            className='uk-child-width-1-2@s uk-child-width-1-3@m uk-grid-large uk-grid-match uk-flex-center uk-grid'
            data-uk-grid
          >
            <div className='uk-first-column'>
              <div className='uk-card uk-card-none card-icon'>
                <div className='uk-card-media-top'>
                  <i className='material-icons icon-size'>apartment</i>
                </div>
                <div className='uk-card-body'>
                  <h3 className='uk-card-title uk-text-bold'>
                    Building Maintenance
                  </h3>
                  <p>
                    <strong>
                      Keep your business place running smooth and looking great!
                    </strong>
                  </p>
                  <p>
                    {`Keep your business place running smoothly with preventative
                    and routined maintenance. Keep your business place in mint
                    condition or give it the TLC it needs to shine! Ample
                    maintenance guarantees to always have your space looking and
                    functioning at it's highest potential.`}
                  </p>
                  <p>
                    <strong className='font-color'>
                      Talk to specialist about scheduled maintenance.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className='uk-card uk-card-none card-icon'>
                <div className='uk-card-media-top'>
                  <i className='material-icons icon-size'>
                    settings_input_composite
                  </i>
                </div>
                <div className='uk-card-body'>
                  <h3 className='uk-card-title uk-text-bold'>
                    New Installation
                  </h3>
                  <p>
                    <strong>Get that much desired Upgrade!</strong>
                  </p>
                  <p>
                    {`Do you have a project or idea that
            you would like to bring to life? We provide our clients with a wide
            range of contracting services at competitive market rates. If you’re
            looking for a certified expert for your next project, please get in
            touch with us today. We would be more than happy to answer all of
            your questions.`}
                  </p>
                  <p>
                    <strong className='font-color'>
                      Talk to specialist about a one time service.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className='uk-card uk-card-none card-icon'>
                <div className='uk-card-media-top'>
                  <i className='material-icons icon-size'>build</i>
                </div>
                <div className='uk-card-body'>
                  <h3 className='uk-card-title uk-text-bold'>
                    Emergency Repair
                  </h3>
                  <p>
                    <strong>Ready and willing when you need us most!</strong>
                  </p>
                  <p>
                    {`Let's be honest, things
            happen! Fortunately we are available 24/7 to come to the rescue! Our
            clients are the number one priority, and we make the effort to
            ensure that they are completely satisfied with our work.`}
                  </p>
                  <p>
                    <strong className='font-color'>
                      Talk to a specialist about having us on call.
                    </strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* NEXT SECTION */}
    <div className='uk-cover-container uk-section-muted'>
      <div className='uk-section uk-section-large uk-background-muted uk-flex uk-flex-column'>
        <div className='uk-container uk-position-relative'>
          <div className='section-title-center uk-margin-medium-bottom'>
            <h2 className='section-title uk-margin-remove uk-text-center uk-text-bold'>
              Services Offered
            </h2>
            <p className='uk-margin-small-top uk-margin-remove-bottom uk-text-lead uk-text-center'>
              Ample Maintenance handles any project, from small to large,
              commercial to residential.
            </p>
          </div>
          <ul
            className='uk-subnav uk-subnav-pill uk-flex-center'
            data-uk-switcher='animation: uk-animation-fade'
          >
            <li className='uk-active'>
              <a
                className='uk-border-pill font-size'
                href='#'
                aria-expanded='true'
              >
                Commercial
              </a>
            </li>
            <li>
              <a
                className='uk-border-pill font-size'
                href='#'
                aria-expanded='false'
              >
                Residential
              </a>
            </li>
          </ul>
          <ul
            className='uk-switcher uk-margin-medium-top'
            style={{ touchAction: 'pan-y pinch-zoom' }}
          >
            <li className='uk-active'>
              <div
                className='uk-child-width-expand@m uk-grid-match uk-flex-center uk-grid uk-grid-stack'
                data-uk-grid
              >
                <div className='uk-first-column'>
                  <div
                    className='uk-card uk-card-large uk-card-none uk-grid-collapse uk-margin uk-grid'
                    data-uk-grid
                  >
                    <div className='uk-flex-last@m uk-card-media-right uk-width-1-2@m center-image'>
                      <img
                        src={Commercial}
                        data-src={Commercial}
                        alt='Topics Management with <mark>Superpowers</mark>'
                        data-uk-img
                        className='image'
                      />
                    </div>
                    <div className='uk-flex uk-flex-middle uk-width-expand@m uk-first-column'>
                      <div className='uk-card-body'>
                        <h3 className='uk-h1'>
                          Keep your commerical space{' '}
                          <mark className='mark'>
                            looking and functioning first-rate!
                          </mark>
                        </h3>
                        <p>
                          <strong>The condition of your workplace</strong> says
                          a lot about the condition of the company. Make your
                          commercial building{' '}
                          <strong>inviting and exciting.</strong> Show pride in
                          your <strong>small business or storefront.</strong>{' '}
                          Have a workplace{' '}
                          <strong>
                            that inspires, and drives yourself and your
                            employees.
                          </strong>
                        </p>

                        <p>
                          At <strong>Ample Maintenance</strong> we provide an
                          <strong>array of services</strong> for your{' '}
                          <strong>commercial space</strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li className=''>
              <div
                className='uk-child-width-expand@m uk-grid-match uk-flex-center uk-grid uk-grid-stack'
                data-uk-grid
              >
                <div className='uk-first-column'>
                  <div
                    className='uk-card uk-card-large uk-card-none uk-grid-collapse uk-margin uk-grid uk-grid-stack'
                    data-uk-grid
                  >
                    <div className='uk-flex-last@m uk-card-media-right uk-width-1-2@m center-image'>
                      <img
                        src={Residential}
                        data-src={Residential}
                        alt='Managing Kafka Brokers is enjoyable'
                        data-uk-img
                        className='image'
                      />
                    </div>
                    <div className='uk-flex uk-flex-middle uk-width-expand@m uk-first-column'>
                      <div className='uk-card-body'>
                        <h3 className='uk-h1'>
                          Make your{' '}
                          <mark className='mark'>house or apartment home!</mark>
                        </h3>
                        <p>
                          Get a newly vacated apartment{' '}
                          <strong>renovated and ready to list</strong> for new
                          tenants. Keep your building{' '}
                          <strong>
                            looking and functioning like brand new.
                          </strong>{' '}
                          Take pride in <strong>your home.</strong>
                        </p>
                        <p>
                          At <strong>Ample Maintenance</strong> we provide an
                          <strong>array of services</strong> for your{' '}
                          <strong>home or apartment</strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <ContactSection />
    </div>
  </Layout>
);

export default IndexPage;
