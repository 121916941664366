import React from 'react';
import { Link } from 'gatsby';
import './contactSection.scss';

const classNames = require('classnames');

interface Props {
  isContactPage?: boolean;
}
const ContactSection = ({ isContactPage }: Props) => {
  return (
    <div className='uk-cover-container'>
      <div className='uk-section uk-section-large uk-background-default'>
        <div className='uk-container uk-container-xsmall uk-position-relative uk-text-center'>
          <div
            data-uk-scrollspy='cls: uk-animation-slide-bottom-medium; repeat: true'
            className='uk-scrollspy-inview uk-animation-slide-bottom-medium'
          >
            <h3
              className={classNames('uk-margin-remove-top', {
                'section-title-large': isContactPage,
                'section-title': !isContactPage,
              })}
            >
              <Link to='/contanct' className='plain-link'>
                Contact <mark className='mark'>Ample Maintenance</mark>
              </Link>
            </h3>
            {isContactPage && (
              <div className='uk-text-lead uk-margin-bottom'>
                <div>
                  We are committed to all of our clients, and look forward
                  working with you.
                </div>
                <div>Questions, comments or special requests?</div>
                <div>
                  <strong>We would love to hear from you</strong>!
                </div>
              </div>
            )}
            <div className='uk-margin-medium-bottom'>
              <p
                className={classNames({
                  'uk-text-lead': !isContactPage,
                  'uk-text-lead-large': isContactPage,
                })}
              >
                60-13 55th St Maspeth, New York 11378
              </p>
              <p
                className={classNames({
                  'uk-text-lead': !isContactPage,
                  'uk-text-lead-large': isContactPage,
                })}
              >
                <mark className='mark'>amplemaintenancenyc</mark>@gmail.com
              </p>
              <p
                className={classNames({
                  'uk-text-lead': !isContactPage,
                  'uk-text-lead-large': isContactPage,
                })}
              >
                Call us: <mark className='mark'>(631) 496-0164</mark>
              </p>
            </div>

            <div className='uk-article-content'>
              <div data-uk-margin>
                <a
                  className='uk-button uk-button-primary uk-button-large uk-width-220 uk-first-column margin'
                  href='mailto:amplemaintenancenyc@gmail.com'
                >
                  Email
                </a>
                <a
                  className='uk-button uk-button-primary-outline uk-button-large uk-width-220 padding margin'
                  href='tel:+1-631-496-0164'
                >
                  Call
                </a>
              </div>
            </div>
            <div
              className='uk-grid uk-flex-center uk-text-center uk-margin'
              data-uk-grid
            >
              {/* <span
                    data-uk-icon='icon: facebook; ratio: 2'
                    className='uk-width-auto'
                  ></span>
                  <span
                    data-uk-icon='icon: twitter; ratio: 2'
                    className='uk-width-auto'
                  ></span>
                  <span
                    data-uk-icon='icon: linkedin; ratio: 2'
                    className='uk-width-auto'
                  ></span> */}
              <a
                href='https://www.instagram.com/amplemaintenance/'
                data-uk-icon='icon: instagram; ratio: 2'
                className='black'
                aria-label='Instagram'
                rel='noopener'
                target='_blank'
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
